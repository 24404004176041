<template>
    <div class="searchContent">
         <!-- history -->
        <searchBar @show="isShow" :searchValue="searchValue" ></searchBar>
        <searchHistory @show="isShow" v-show="show"  ></searchHistory>
        <searchResult v-show="!show" :searchValue="searchValue" ></searchResult>
    </div>
</template>

<script>
import searchBar from '../components/Search/SearchBar.vue'
import searchResult from '../components/Search/SearchResult.vue'
import searchHistory from '../components/Search/SearchHistory.vue'

export default {
    components:{searchBar,searchHistory,searchResult},
    data() {
        return {
            show:true,
            searchValue: ''
        }
    },
    methods:{
        isShow(val){
            this.show = val
        }
    },
    mounted(){
        if(this.$route.params.value){
            this.show = this.$route.params.show
            this.searchValue = this.$route.params.value
        }

        
    }
}
</script>

<style scoped>
.searchContent{
    height: 100vh;
    background-color: rgb(255, 255, 255);
}

</style>