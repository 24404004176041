<template>
  <div>

    <div class="search_history_lists">
        <div class='title'>
            <div class="search_history">历史搜索</div>
            <div @click="deleteHistory" class="clearHistory">
              清除搜索历史
              <img src='../../assets/images/delete.png' alt="" class="clearHistory_image">
            </div>
        </div>

        <div class="search-history">
            <div v-for="(item,index) of history" :key="index" class="searchRecord" @click="goSearch(item)">
                {{item}}
            </div>
        </div>
    </div>    

  </div>
</template>

<script>
export default {
    data() {
        return {
            history:[]
        }
    },
    methods: {
        deleteHistory() {
            this.history=[]
            localStorage.setItem("history",[])
        },
        getHistory(val){
            this.history = val
        },
        goSearch(item){
            this.$bus.$emit('value',item)
            this.$bus.$emit('inputVal',item)
            this.$emit('show',false)
        }
    },
    mounted() {
        if(localStorage.getItem("history")){
            this.history = JSON.parse(localStorage.getItem("history"))
        }
        this.$bus.$on("history",this.getHistory)
    },
}
</script>

<style scoped>

.search_history_lists .search-history{
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 15px;
    
}

.search_history_lists .searchRecord{
    /* border: 1px black solid; */
    border-radius: 10px;
    padding: 10px 20px;
    margin: 10px;
    font-size: 10px;
    color: black;
    line-height: 14px;
    background-color: #f7f8fa;
  }

  .search_history_lists {
    width: 100%;
    position: relative;
}

.title{
    width: 100%;
    height: 30px;
    /* background-color: red; */
    display: flex;
    position: relative;
}

.search_history {
    position: absolute;
    top:6px;
    left: 10px;
    font-size: 14px;
    margin-left: 10px;
    color: black;    

}

.clearHistory {
    position: absolute;
    width: 100px;
    top:9px;
    right:5px;
    font-size: 12px;
    color: rgb(145, 142, 142);    
}

.clearHistory_image {
    position: absolute;
    top: -2px;
    width: 23px;
    height: 23px;
}

img {
    /* position: absolute; */
    width: 100%;
}


</style>