<template>
  <div >
    <!-- search warp  -->
    <div class="search-wrap">
        <form action="/">
            <van-search
                v-model.trim="value"
                show-action
                placeholder="请输入搜索关键词"
                
                shape="round"
                @search="onSearch"
                @clear ="onClear"
            >
                <template #action>
                    <div  @click="onCancel">取消</div>
                </template>
            </van-search>
        </form>
    </div>
  
  </div>
</template>

<script>

export default {
    name:"search",
    data() {
        return {
            value:'',
            history: []
        }
    },

    props:["searchValue"],
    methods: {
        onSearch(val) {
            if(!this.history.includes(val)&& val){
                this.history.push(val)
                //把历史数据列表传给历史组件
                this.$bus.$emit('history',this.history)
            }

            //把搜索的值传给搜索结果组件
            this.$bus.$emit('value',val)
            //决定展示哪个组件，把数据传给父组件
            this.$emit('show',false)
        },
        onCancel() {
            this.$router.push("/main")      
        },

        onClear(){
            this.$emit('show',true)
        }
     },
     watch:{
         value(val){
            val = val.replace(/\s/g,"")
            this.value = val
            
         },
         
        searchValue(val){
            this.value = val
        },

         history(value){
            if(!this.history.includes(value)){
                localStorage.setItem('history', JSON.stringify(value))
            }

         }
     },

     mounted() {
         

         if(localStorage.getItem("history")){
             this.history = JSON.parse(localStorage.getItem("history"))
         }
        const that = this
        this.$bus.$on("inputVal",(value)=>{
            that.value = value
        })
     },

}
</script>

<style>


/* search */
.search-wrap {
    height: 55px;
    width: 100%;
    overflow: hidden;
    position:relative;
    min-width: 320px;
    max-width: 640px;
    z-index: 20;
    background: white;
}

.van-search__action {
    color: #1989fa;

}


</style>