<template>
  <div>
     
      <!-- 用户发布 -->
      <div class="user-poster" id='user-poster'>
          <div class="user-root" v-for="(p,index) in posterInfo" :key="index">
              <!-- 用户头像和id -->
              <div class="header">
                  <div class="user-head">
                      <a href="#">
                          <img :src="p.avatar" alt="">
                      </a>
                  </div>

                  <div class="user-name">
                      <div class="user-id">{{p.nickname}}</div>
                      <div class="user-time">{{p.created}}</div>
                  </div>            
              </div>

              <div class="user-content">
                    <div  @click="goSearch(p.difficulty)" style="color: dodgerblue;">#{{p.difficulty}} </div> 
                    <div @click="goSearch(p.location)" style="color: dodgerblue; margin-left: 8px;"> #{{p.location}} </div>
                     
              </div>
              <div v-show="p.content" class="user-content1">
                  {{p.content}}
              </div>
      
              <div class="user-photo">
                    <img :src="p.pic" alt="" @click="getImage(index)">
              </div>

          </div>        
      </div>

  </div>
</template>




<script>
import { ImagePreview } from 'vant';
  export default {
    name:"Main",
      data() {
        return {
          loading: true,
          upLoadings:{},
          posterInfo:[]
        };
      },
    
    props:["searchValue"],

    methods:{   
        getImage(index){
            ImagePreview([
                this.posterInfo[index].pic
            ]);
        },
        

        goSearch(item){
            this.$bus.$emit('value',item)
            this.$bus.$emit('inputVal',item)
     
        },

        post(val){
            const _this =this
            // console.log(val)
            _this.$axios.get("/blogs").then(
                res =>{
                    // console.log(res)
                    const Info = res.data.data.records
                    const option = []
                    for (let i = 0; i < Info.length; i++) {
                        var store = Info[i]
                        if(store.content.toLowerCase().includes(val.toLowerCase())||store.difficulty.toLowerCase().includes(val.toLowerCase())||store.location.toLowerCase().includes(val.toLowerCase())){
                            option.push(store)
                        }
                        
                    }
                    _this.posterInfo = option
                    // console.log(_this.posterInfo)
                }
            )
        }

    },

    watch:{
        searchValue(val){
           this.post(val)
        },

    },
    
    mounted(){
        this.$bus.$on("value",this.post)
    }
  }
</script>
  
<style scoped>
  
.user-poster {

    width:  100%;
    height: auto;
    box-sizing: border-box;
    border-top: solid 1px #e6e6e6;
    background-color: white;
    color: black;
}

.user-root {
    /* border-radius: 25px; */
    overflow: hidden;
    border-top: solid 1px #e6e6e6;
}

.header{
    margin-top: 10px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    height: 45px;
}

.user-head {
    width: 35px;
    height: 48px;
    /* background-color: rgb(68, 138, 138); */
}

.user-head img {
    width: 100%;
    border-radius: 50%;
    overflow: hidden;
    background-color: white;
}

.user-name {
    margin-left: 5px;
    color:black;
}
.user-time {
    color: #c4c4c4;
    font-size: 1px;
    margin: 3px;
}

.user-content {
    display: flex;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    color: black;
    /* background-color: yellow; */
}


.user-content1 {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    color: black;
    /* background-color: yellow; */
}

.user-photo{
    display: flex;
    float: left;
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    width: 60%;

    /* background-color: wheat; */

}



.user-photo img{

    width: 100%;
    
}

.user-footer {
    display: flex;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    height: 30px;
    /* background-color: red; */
    z-index: 300;
}

.user-footer div{
    display: flex;
    width: 50px;
    height: 20px;
    /* margin-top: 5px; */
}
.user-footer div img {
    width: 100%;
}

.user-zan {
    margin-left: 15px;
    color: #8f9599;
}

.user-comment {
    color: #8f9599;
}


.search-login a{
    text-decoration:none;
    color:black;
}
</style>